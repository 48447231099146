import _defineProperty from "/app/apps/frontend/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import getConfig from 'next/config';
import { split } from 'lodash';
const {
  publicRuntimeConfig = {}
} = getConfig() || {};
export const ENV_APP_ENV = "production";
export const ENV_APP_NAME = publicRuntimeConfig.APP_NAME;
export const AWS_ENV = publicRuntimeConfig.AWS_ENV;
export const ENV_HOST = publicRuntimeConfig.HOST;
export const ENV_API_URL = publicRuntimeConfig.API_URL;
export const ENV_INTERNAL_API_URL = process.env.INTERNAL_API_URL;
export const ANAC_API_URL = process.env.ANAC_API_URL;
export const ANAC_API_ACCESS_TOKEN = process.env.ANAC_API_ACCESS_TOKEN; // AUTH
// Cognito enpoints

export const COGNITO_TOKEN_ENDPOINT = process.env.TOKEN_ENDPOINT;
export const COGNITO_USER_INFO_ENDPOINT = process.env.USER_INFO_ENDPOINT;
export const ENV_COGNITO_ISSUER = process.env.ISSUER; // Cognito M2M

export const COGNITO_CLIENT_ID = process.env.LUBANAC_ANAC_CLIENT_ID;
export const COGNITO_CLIENT_SECRET = process.env.LUBANAC_ANAC_CLIENT_SECRET; // Gigya

export const ENV_GIGYA_CLIENT_ID = process.env.GIGYA_CLIENT_ID;
export const ENV_GIGYA_CLIENT_SECRET = process.env.GIGYA_CLIENT_SECRET;
export const ENV_GIGYA_PROVIDER_IDENTITY = publicRuntimeConfig.GIGYA_PROVIDER_IDENTITY;
export const ENV_AUTH_PROVIDERS_ANAC = split(publicRuntimeConfig.AUTH_PROVIDERS_ANAC, ',');
export const ENV_AUTH_PROVIDERS_DIAGOTRUCKS = split(publicRuntimeConfig.AUTH_PROVIDERS_DIAGOTRUCKS, ',');
const envVariables = {
  required: {
    ENV_FRONTEND_URL: process.env.CLIENT_URL
  },
  optional: {
    ENV_NODE_ENV: "production" || 'development',
    ENV_PORT: process.env.PORT || 4000,
    ENV_ENVIRONMENT: process.env.ENVIRONMENT
  }
};
export const checkRequiredEnvVariables = () => {
  const requiredEnvVariables = envVariables.required;
  const missingEnvVariables = Object.entries(requiredEnvVariables).filter(_ref => {
    let [, value] = _ref;
    return typeof value === 'undefined';
  });

  if (missingEnvVariables.length) {
    // eslint-disable-next-line max-len, no-console
    console.error('\x1b[31m%s\x1b[0m', `Missing required environment variables in .env file: ${missingEnvVariables.map(_ref2 => {
      let [name] = _ref2;
      return name.replace('ENV_', '');
    })}`);
    process.exit(1);
  }
};
export const {
  ENV_NODE_ENV,
  ENV_PORT,
  ENV_FRONTEND_URL,
  ENV_ENVIRONMENT
} = _objectSpread(_objectSpread({}, envVariables.required), envVariables.optional);