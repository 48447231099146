import _defineProperty from "/app/apps/frontend/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// THIS IS A GENERATED FILE, DO NOT EDIT IT!
// tslint:disable
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */

export let DocumentLocale;

(function (DocumentLocale) {
  DocumentLocale["Cs"] = "CS";
  DocumentLocale["Da"] = "DA";
  DocumentLocale["De"] = "DE";
  DocumentLocale["En"] = "EN";
  DocumentLocale["Es"] = "ES";
  DocumentLocale["Fr"] = "FR";
  DocumentLocale["Hu"] = "HU";
  DocumentLocale["It"] = "IT";
  DocumentLocale["Ko"] = "KO";
  DocumentLocale["Nl"] = "NL";
  DocumentLocale["No"] = "NO";
  DocumentLocale["Pl"] = "PL";
  DocumentLocale["Pt"] = "PT";
  DocumentLocale["PtPt"] = "PT_PT";
  DocumentLocale["Ro"] = "RO";
  DocumentLocale["Ru"] = "RU";
  DocumentLocale["Sv"] = "SV";
  DocumentLocale["Tr"] = "TR";
  DocumentLocale["Zh"] = "ZH";
})(DocumentLocale || (DocumentLocale = {}));

export let FormCanSelectBarcodeError;

(function (FormCanSelectBarcodeError) {
  FormCanSelectBarcodeError["BarcodeNumberTooOldErtvelde"] = "BARCODE_NUMBER_TOO_OLD_ERTVELDE";
  FormCanSelectBarcodeError["CustBarcodeAlreadyRegistered"] = "CUST_BARCODE_ALREADY_REGISTERED";
  FormCanSelectBarcodeError["CustBarcodeAlreadyUsed"] = "CUST_BARCODE_ALREADY_USED";
  FormCanSelectBarcodeError["CustBarcodeMustNotBeOptionBarcodeErtvelde"] = "CUST_BARCODE_MUST_NOT_BE_OPTION_BARCODE_ERTVELDE";
  FormCanSelectBarcodeError["CustBarcodeNotAllowedOnThisPartErtvelde"] = "CUST_BARCODE_NOT_ALLOWED_ON_THIS_PART_ERTVELDE";
  FormCanSelectBarcodeError["CustBarcodeNotAssignedToCustomerCodeErtvelde"] = "CUST_BARCODE_NOT_ASSIGNED_TO_CUSTOMER_CODE_ERTVELDE";
  FormCanSelectBarcodeError["SampleDiagTypeNotAllowedForCustomerOrPartErtvelde"] = "SAMPLE_DIAG_TYPE_NOT_ALLOWED_FOR_CUSTOMER_OR_PART_ERTVELDE";
  FormCanSelectBarcodeError["UnknownBarcodeErtvelde"] = "UNKNOWN_BARCODE_ERTVELDE";
})(FormCanSelectBarcodeError || (FormCanSelectBarcodeError = {}));

export let FormCanSelectBarcodeOptionsError;

(function (FormCanSelectBarcodeOptionsError) {
  FormCanSelectBarcodeOptionsError["BarcodeNumberTooOldErtvelde"] = "BARCODE_NUMBER_TOO_OLD_ERTVELDE";
  FormCanSelectBarcodeOptionsError["CustBarcodeAlreadyRegistered"] = "CUST_BARCODE_ALREADY_REGISTERED";
  FormCanSelectBarcodeOptionsError["CustBarcodeAlreadyUsed"] = "CUST_BARCODE_ALREADY_USED";
  FormCanSelectBarcodeOptionsError["CustBarcodeNotAllowedOnThisPartErtvelde"] = "CUST_BARCODE_NOT_ALLOWED_ON_THIS_PART_ERTVELDE";
  FormCanSelectBarcodeOptionsError["CustBarcodeNotAssignedToCustomerCodeErtvelde"] = "CUST_BARCODE_NOT_ASSIGNED_TO_CUSTOMER_CODE_ERTVELDE";
  FormCanSelectBarcodeOptionsError["ForbiddenOption"] = "FORBIDDEN_OPTION";
  FormCanSelectBarcodeOptionsError["ForbiddenOptionValue"] = "FORBIDDEN_OPTION_VALUE";
  FormCanSelectBarcodeOptionsError["SampleDiagTypeNotAllowedForCustomerOrPartErtvelde"] = "SAMPLE_DIAG_TYPE_NOT_ALLOWED_FOR_CUSTOMER_OR_PART_ERTVELDE";
  FormCanSelectBarcodeOptionsError["UnknownCustBarcode"] = "UNKNOWN_CUST_BARCODE";
  FormCanSelectBarcodeOptionsError["UnknownOptionBarcode"] = "UNKNOWN_OPTION_BARCODE";
})(FormCanSelectBarcodeOptionsError || (FormCanSelectBarcodeOptionsError = {}));

export let FormCanSelectOilWarning;

(function (FormCanSelectOilWarning) {
  FormCanSelectOilWarning["NotForEngines"] = "NOT_FOR_ENGINES";
  FormCanSelectOilWarning["UnknownOil"] = "UNKNOWN_OIL";
})(FormCanSelectOilWarning || (FormCanSelectOilWarning = {}));

export let Kpi;

(function (Kpi) {
  Kpi["Analyzes"] = "ANALYZES";
  Kpi["MachinesUrgent"] = "MACHINES_URGENT";
  Kpi["NewSample"] = "NEW_SAMPLE";
  Kpi["PartsInAlert"] = "PARTS_IN_ALERT";
  Kpi["Reports"] = "REPORTS";
  Kpi["ReportsToValidate"] = "REPORTS_TO_VALIDATE";
  Kpi["ReportsWaitingForFeedback"] = "REPORTS_WAITING_FOR_FEEDBACK";
  Kpi["SamplesInProgress"] = "SAMPLES_IN_PROGRESS";
  Kpi["Webregistration"] = "WEBREGISTRATION";
})(Kpi || (Kpi = {}));

export let KpiTimeOption;

(function (KpiTimeOption) {
  KpiTimeOption["CurrentYear"] = "CURRENT_YEAR";
  KpiTimeOption["Last_3Months"] = "LAST_3_MONTHS";
  KpiTimeOption["Last_6Months"] = "LAST_6_MONTHS";
  KpiTimeOption["LastMonth"] = "LAST_MONTH";
  KpiTimeOption["LastYear"] = "LAST_YEAR";
})(KpiTimeOption || (KpiTimeOption = {}));

export let Profile;

(function (Profile) {
  Profile["Customer"] = "CUSTOMER";
  Profile["Other"] = "OTHER";
  Profile["Technical"] = "TECHNICAL";
})(Profile || (Profile = {}));

export let Role;

(function (Role) {
  Role["AdminAnac"] = "ADMIN_ANAC";
  Role["Other"] = "OTHER";
  Role["SuperAdmin"] = "SUPER_ADMIN";
})(Role || (Role = {}));

export let SampleStatus;

(function (SampleStatus) {
  SampleStatus["Green"] = "GREEN";
  SampleStatus["Grey"] = "GREY";
  SampleStatus["Orange"] = "ORANGE";
  SampleStatus["Red"] = "RED";
  SampleStatus["RunningIn"] = "RUNNING_IN";
  SampleStatus["Urgent"] = "URGENT";
})(SampleStatus || (SampleStatus = {}));

export let ScopePerimeter;

(function (ScopePerimeter) {
  ScopePerimeter["Cc"] = "CC";
  ScopePerimeter["Cd"] = "CD";
  ScopePerimeter["Co"] = "CO";
  ScopePerimeter["Cu"] = "CU";
  ScopePerimeter["Dl"] = "DL";
  ScopePerimeter["Group"] = "GROUP";
  ScopePerimeter["Tbo"] = "TBO";
  ScopePerimeter["Td"] = "TD";
})(ScopePerimeter || (ScopePerimeter = {}));

export let ScopeRole;

(function (ScopeRole) {
  ScopeRole["Admin"] = "ADMIN";
  ScopeRole["Client"] = "CLIENT";
  ScopeRole["Consultant"] = "CONSULTANT";
  ScopeRole["Dealer"] = "DEALER";
  ScopeRole["Tbo"] = "TBO";
})(ScopeRole || (ScopeRole = {}));

export let UserLocale;

(function (UserLocale) {
  UserLocale["Cs"] = "CS";
  UserLocale["De"] = "DE";
  UserLocale["En"] = "EN";
  UserLocale["Es"] = "ES";
  UserLocale["Fr"] = "FR";
  UserLocale["Hu"] = "HU";
  UserLocale["It"] = "IT";
  UserLocale["Ko"] = "KO";
  UserLocale["Nl"] = "NL";
  UserLocale["Pt"] = "PT";
  UserLocale["Ru"] = "RU";
  UserLocale["Tr"] = "TR";
  UserLocale["Zh"] = "ZH";
})(UserLocale || (UserLocale = {}));

export let WebSite;

(function (WebSite) {
  WebSite["Anac"] = "ANAC";
  WebSite["Diagotrucks"] = "DIAGOTRUCKS";
})(WebSite || (WebSite = {}));

export const DiagTypeFieldsFragmentDoc = gql`
    fragment diagTypeFields on DiagType {
  id
  DIT_DIAG_TYPELINK
  DIT_CODE
  DIT_DESCRIPTION
  DIT_DESC_DIAGOREN
}
    `;
export const MachineFieldsFragmentDoc = gql`
    fragment machineFields on Machine {
  id
  canEdit
  MCH_MACHLINK
  MCH_MACHCODE
  MCH_ID
  MCH_OWNERSNAME
  MCH_VEHTYPE
  MCH_MAK_ID
  parts {
    id
    PRT_PARTLINK
    PRT_PARTCODE
    PRT_ID
    PRT_CAPACITY
    PRT_PARTTYPE
    PRT_PDFNCODE
    PRT_COMBTYPE
    PRT_FUELTYPE
    PRT_OILMAKEDEF
    PRT_OILTYPEDEF
    PRT_OILGRADEDEF
    PRT_DUALFUEL
    PRT_PARTKIND
    tblMt4 {
      MT4_FULLPARTMAKE
    }
    lastSample {
      status
      SMP_DTCARD
      SMP_SAMPLINK
      SMP_DTSAMPLING
      isValidate
      canView
    }
  }
  tblMt2 {
    MT2_FULLVEHMAKE
  }
  customer {
    CST_CUSTCODE
    CST_GROUPCODE
    CST_CUSTLINK
    CST_NAME
    CST_RESPCODE
    id
  }
}
    `;
export const NewSampleFieldsFragmentDoc = gql`
    fragment newSampleFields on NewSampleRow {
  id
  canEdit
  NEWSAMPNR
  CUSTLINK
  MACHLINK
  PARTLINK
  MCH_REGNO
  MCH_REGNO2
  MCH_MAK_ID
  MCH_VEHMAKE
  MCH_VEHMAKE_OTHER
  MCH_VEHTYPE
  MCH_VEHTYPE_OTHER
  MCH_MACHCODE
  PRT_REGNO
  PRT_PDFNCODE
  PRT_PARTMAKE
  PRT_PARTMAKE_OTHER
  PRT_PARTTYPE
  PRT_PARTTYPE_OTHER
  PRT_PARTCODE
  CBN_LABEL
  DTSAMPLING
  SUMPMILEAGE
  TOTMILEAGE
  UNITMILEAGE
  SMPMT
  OILCONSUMP
  OILCONSUMP_UNIT
  OILMAKE
  OILMAKE_OTHER
  OILTYPE
  OILTYPE_OTHER
  OILGRADE
  OILGRADE_OTHER
  OVERHAUL
  NEWCOMP
  NEWMACH
  CNTREP
  REGDATE
  REGUSER
  COMMENTS
  PRT_FUELTYPE
  PRT_DUALFUEL
  DEALERCODE
  PRT_PARTMAKE_ORIG
  PRT_PARTTYPE_ORIG
  DIAGTYPE
  ALFALABEL_OPT1
  ALFALABEL_OPT2
  ALFALABEL_OPT3
  ALFALABEL_OPT4
  ALFALABEL_OPT5
  ALFALABEL_OPT6
  PROCESSED
  PRT_CAPACITY
  H2O_COOLANT1
  H2O_COOLANT2
  ARRIVED_IN_LAB
  EMAIL_DEST
  INTERNAL_MEMO
  LBM_PORT
  LBM_PURIFIERFILTER
  LBM_DRAIN_FO_S
  LBM_DRAIN_ENG_LOAD
  LBM_DRAIN_LOFR_G_KWH
  LBM_CLE_PRIMAIRE
  LBM_CATEG
  LBM_LABORATORY
  part {
    id
    PRT_PARTLINK
    PRT_PARTCODE
    PRT_ID
    PRT_PARTTYPE
    PRT_PDFNCODE
    PRT_COMBTYPE
    PRT_CAPACITY
    PRT_DUALFUEL
    PRT_FUELTYPE
    tblMt4 {
      MT4_FULLPARTMAKE
    }
  }
  partKind {
    PDF_CODE
    PDF_COMBTYPE
  }
  machine {
    id
    MCH_MACHLINK
    MCH_MAK_ID
    MCH_MACHCODE
    MCH_ID
    MCH_OWNERSNAME
    MCH_VEHTYPE
    tblMt2 {
      MT2_FULLVEHMAKE
    }
  }
  customer {
    CST_CUSTCODE
    CST_GROUPCODE
  }
}
    `;
export const SampleFieldsFragmentDoc = gql`
    fragment sampleFields on SampleRow {
  id
  status
  canEdit
  SMP_ALFALABEL
  SMP_DTSAMPLING
  SMP_DTCARD
  SMP_ICPROD
  SMP_PARTLINK
  SMP_SEQNR
  SMP_DIAGTYPE
  part {
    id
    PRT_PARTLINK
    PRT_PARTCODE
    PRT_ID
    PRT_PARTTYPE
    PRT_PDFNCODE
    PRT_COMBTYPE
    tblMt4 {
      MT4_FULLPARTMAKE
    }
    machine {
      id
      MCH_MACHLINK
      MCH_MACHCODE
      MCH_ID
      MCH_OWNERSNAME
      MCH_VEHTYPE
      tblMt2 {
        MT2_FULLVEHMAKE
      }
      customer {
        CST_CUSTCODE
        CST_GROUPCODE
      }
      tblMt2 {
        MT2_FULLVEHMAKE
      }
    }
  }
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on User {
  id
  email
  firstName
  lastName
  documentLocale
  locale
  role
  website
  activatedAt
  activate
  kitRequestEnabled
  createdAt
  updatedAt
  profile
  lastLoginAt
  cognitoUid
  scopes {
    id
    role
    perimeter
    codes
    userId
  }
  kpis {
    id
    isMainKpi
    kpi
    timeOption
  }
}
    `;
export const FetchClientsDocument = gql`
    query fetchClients {
  fetchClients {
    client
    access
    scopes {
      allowAll
      list {
        role
        perimeter
        codes
      }
    }
  }
}
    `;
/**
 * __useFetchClientsQuery__
 *
 * To run a query within a React component, call `useFetchClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchClientsQuery({
 *   variables: {
 *   },
 * });
 */

export function useFetchClientsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FetchClientsDocument, options);
}
export function useFetchClientsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FetchClientsDocument, options);
}
export function useFetchClientsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FetchClientsDocument, options);
}
export const SyncRedisDocument = gql`
    query syncRedis {
  syncRedis {
    success
  }
}
    `;
/**
 * __useSyncRedisQuery__
 *
 * To run a query within a React component, call `useSyncRedisQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncRedisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncRedisQuery({
 *   variables: {
 *   },
 * });
 */

export function useSyncRedisQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SyncRedisDocument, options);
}
export function useSyncRedisLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SyncRedisDocument, options);
}
export function useSyncRedisSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SyncRedisDocument, options);
}
export const UpdateClientAccessDocument = gql`
    mutation updateClientAccess($client: ClientInput!) {
  updateClientAccess(client: $client) {
    success
  }
}
    `;

/**
 * __useUpdateClientAccessMutation__
 *
 * To run a mutation, you first call `useUpdateClientAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientAccessMutation, { data, loading, error }] = useUpdateClientAccessMutation({
 *   variables: {
 *      client: // value for 'client'
 *   },
 * });
 */
export function useUpdateClientAccessMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(UpdateClientAccessDocument, options);
}
export const RefreshClientTokenDocument = gql`
    mutation refreshClientToken($clientName: String!) {
  refreshClientToken(clientName: $clientName) {
    token
  }
}
    `;

/**
 * __useRefreshClientTokenMutation__
 *
 * To run a mutation, you first call `useRefreshClientTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshClientTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshClientTokenMutation, { data, loading, error }] = useRefreshClientTokenMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useRefreshClientTokenMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(RefreshClientTokenDocument, options);
}
export const CreateClientDocument = gql`
    mutation createClient($client: ClientInput!) {
  createClient(client: $client) {
    token
  }
}
    `;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      client: // value for 'client'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(CreateClientDocument, options);
}
export const DeleteClientDocument = gql`
    mutation deleteClient($clientName: String!) {
  deleteClient(clientName: $clientName) {
    success
  }
}
    `;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientMutation, { data, loading, error }] = useDeleteClientMutation({
 *   variables: {
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useDeleteClientMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(DeleteClientDocument, options);
}
export const DealersDocument = gql`
    query dealers {
  dealers {
    CST_CUSTCODE
    CST_NAME
    CST_CUSTLINK
  }
}
    `;
/**
 * __useDealersQuery__
 *
 * To run a query within a React component, call `useDealersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealersQuery({
 *   variables: {
 *   },
 * });
 */

export function useDealersQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(DealersDocument, options);
}
export function useDealersLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(DealersDocument, options);
}
export function useDealersSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(DealersDocument, options);
}
export const DiagTypesDocument = gql`
    query diagTypes {
  diagTypes {
    ...diagTypeFields
  }
}
    ${DiagTypeFieldsFragmentDoc}`;
/**
 * __useDiagTypesQuery__
 *
 * To run a query within a React component, call `useDiagTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiagTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiagTypesQuery({
 *   variables: {
 *   },
 * });
 */

export function useDiagTypesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(DiagTypesDocument, options);
}
export function useDiagTypesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(DiagTypesDocument, options);
}
export function useDiagTypesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(DiagTypesDocument, options);
}
export const CreateFeedbackDocument = gql`
    mutation createFeedback($feedback: FeedbackInput!) {
  createFeedback(feedback: $feedback) {
    DATREG
    RUBR2
    COMM
    RUBR311
    RUBR312
    RUBR321
    RUBR322
    RUBR323
    RUBR324
    RUBR325
    RUBR326
    RUBR331
    RUBR333
    RUBR332
    RUBR334
    RUBR411
    RUBR412
    RUBR413
    RUBR414
    RUBR415
    RUBR416
    RUBR417
    RUBR418
    RUBR419
    RUBR4110
    RUBR4111
    RUBR4112
    RUBR4113
    RUBR4114
    RUBR511
    RUBR512
    SAMPLINK
    PARTLINK
  }
}
    `;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(CreateFeedbackDocument, options);
}
export const FormMachineCustomersDocument = gql`
    query formMachineCustomers {
  formMachineCustomers {
    id
    CST_CUSTLINK
    CST_CUSTCODE
    CST_GROUPCODE
    CST_NAME
    CST_CENTER
    CST_RESPCODE
    CST_CATEGORYDEF
    CST_WITHDEALERS
    CST_CRDVERSION
  }
}
    `;
/**
 * __useFormMachineCustomersQuery__
 *
 * To run a query within a React component, call `useFormMachineCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormMachineCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormMachineCustomersQuery({
 *   variables: {
 *   },
 * });
 */

export function useFormMachineCustomersQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormMachineCustomersDocument, options);
}
export function useFormMachineCustomersLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormMachineCustomersDocument, options);
}
export function useFormMachineCustomersSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormMachineCustomersDocument, options);
}
export const FormMachineKindsDocument = gql`
    query formMachineKinds($locale: String) {
  formMachineKinds(locale: $locale) {
    MAK_ID
    MAK_NAME
  }
}
    `;
/**
 * __useFormMachineKindsQuery__
 *
 * To run a query within a React component, call `useFormMachineKindsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormMachineKindsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormMachineKindsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */

export function useFormMachineKindsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormMachineKindsDocument, options);
}
export function useFormMachineKindsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormMachineKindsDocument, options);
}
export function useFormMachineKindsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormMachineKindsDocument, options);
}
export const FormMachineMakesDocument = gql`
    query formMachineMakes {
  formMachineMakes {
    MT2_VEHMAKE
    MT2_FULLVEHMAKE
    MT2_MAK_ID
  }
}
    `;
/**
 * __useFormMachineMakesQuery__
 *
 * To run a query within a React component, call `useFormMachineMakesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormMachineMakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormMachineMakesQuery({
 *   variables: {
 *   },
 * });
 */

export function useFormMachineMakesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormMachineMakesDocument, options);
}
export function useFormMachineMakesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormMachineMakesDocument, options);
}
export function useFormMachineMakesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormMachineMakesDocument, options);
}
export const FormMachineTypesDocument = gql`
    query formMachineTypes($make: String) {
  formMachineTypes(make: $make) {
    MT3_VEHTYPE
  }
}
    `;
/**
 * __useFormMachineTypesQuery__
 *
 * To run a query within a React component, call `useFormMachineTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormMachineTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormMachineTypesQuery({
 *   variables: {
 *      make: // value for 'make'
 *   },
 * });
 */

export function useFormMachineTypesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormMachineTypesDocument, options);
}
export function useFormMachineTypesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormMachineTypesDocument, options);
}
export function useFormMachineTypesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormMachineTypesDocument, options);
}
export const FormCustomerMachinesDocument = gql`
    query formCustomerMachines($customerId: Int) {
  formCustomerMachines(customerId: $customerId) {
    MCH_MACHLINK
    MCH_ID
  }
}
    `;
/**
 * __useFormCustomerMachinesQuery__
 *
 * To run a query within a React component, call `useFormCustomerMachinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormCustomerMachinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormCustomerMachinesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */

export function useFormCustomerMachinesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormCustomerMachinesDocument, options);
}
export function useFormCustomerMachinesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormCustomerMachinesDocument, options);
}
export function useFormCustomerMachinesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormCustomerMachinesDocument, options);
}
export const FormDiagTypesDocument = gql`
    query formDiagTypes($customerId: Int, $machineKind: Int, $locale: String) {
  formDiagTypes(
    customerId: $customerId
    machineKind: $machineKind
    locale: $locale
  ) {
    DIT_DIAG_TYPELINK
    DIT_DESCRIPTION
  }
}
    `;
/**
 * __useFormDiagTypesQuery__
 *
 * To run a query within a React component, call `useFormDiagTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormDiagTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormDiagTypesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      machineKind: // value for 'machineKind'
 *      locale: // value for 'locale'
 *   },
 * });
 */

export function useFormDiagTypesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormDiagTypesDocument, options);
}
export function useFormDiagTypesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormDiagTypesDocument, options);
}
export function useFormDiagTypesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormDiagTypesDocument, options);
}
export const FormFuelTypesDocument = gql`
    query formFuelTypes($locale: String) {
  formFuelTypes(locale: $locale) {
    FLT_CODE
    FLT_NAME
  }
}
    `;
/**
 * __useFormFuelTypesQuery__
 *
 * To run a query within a React component, call `useFormFuelTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormFuelTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormFuelTypesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */

export function useFormFuelTypesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormFuelTypesDocument, options);
}
export function useFormFuelTypesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormFuelTypesDocument, options);
}
export function useFormFuelTypesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormFuelTypesDocument, options);
}
export const FormPartKindsDocument = gql`
    query formPartKinds($locale: String, $customerId: Int, $diagTypeId: Int, $machineKind: Int) {
  formPartKinds(
    locale: $locale
    customerId: $customerId
    diagTypeId: $diagTypeId
    machineKind: $machineKind
  ) {
    PDF_CODE
    PDF_DESCR
    PDF_COMBTYPE
    PDF_PARTKIND
  }
}
    `;
/**
 * __useFormPartKindsQuery__
 *
 * To run a query within a React component, call `useFormPartKindsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormPartKindsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormPartKindsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      customerId: // value for 'customerId'
 *      diagTypeId: // value for 'diagTypeId'
 *      machineKind: // value for 'machineKind'
 *   },
 * });
 */

export function useFormPartKindsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormPartKindsDocument, options);
}
export function useFormPartKindsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormPartKindsDocument, options);
}
export function useFormPartKindsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormPartKindsDocument, options);
}
export const FormPartMakesDocument = gql`
    query formPartMakes($combType: String, $partKind: String) {
  formPartMakes(combType: $combType, partKind: $partKind) {
    MT4_PARTMAKE
    MAKE_DESCR
  }
}
    `;
/**
 * __useFormPartMakesQuery__
 *
 * To run a query within a React component, call `useFormPartMakesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormPartMakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormPartMakesQuery({
 *   variables: {
 *      combType: // value for 'combType'
 *      partKind: // value for 'partKind'
 *   },
 * });
 */

export function useFormPartMakesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormPartMakesDocument, options);
}
export function useFormPartMakesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormPartMakesDocument, options);
}
export function useFormPartMakesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormPartMakesDocument, options);
}
export const FormPartTypesDocument = gql`
    query formPartTypes($combType: String, $partKind: String, $partMake: String) {
  formPartTypes(combType: $combType, partKind: $partKind, partMake: $partMake) {
    MT5_PARTTYPE
  }
}
    `;
/**
 * __useFormPartTypesQuery__
 *
 * To run a query within a React component, call `useFormPartTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormPartTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormPartTypesQuery({
 *   variables: {
 *      combType: // value for 'combType'
 *      partKind: // value for 'partKind'
 *      partMake: // value for 'partMake'
 *   },
 * });
 */

export function useFormPartTypesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormPartTypesDocument, options);
}
export function useFormPartTypesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormPartTypesDocument, options);
}
export function useFormPartTypesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormPartTypesDocument, options);
}
export const FormDealersDocument = gql`
    query formDealers($custCode: String) {
  formDealers(custCode: $custCode) {
    id
    CST_CUSTLINK
    CST_CUSTCODE
    CST_GROUPCODE
    CST_NAME
    CST_CENTER
  }
}
    `;
/**
 * __useFormDealersQuery__
 *
 * To run a query within a React component, call `useFormDealersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormDealersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormDealersQuery({
 *   variables: {
 *      custCode: // value for 'custCode'
 *   },
 * });
 */

export function useFormDealersQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormDealersDocument, options);
}
export function useFormDealersLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormDealersDocument, options);
}
export function useFormDealersSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormDealersDocument, options);
}
export const FormCustomerDocument = gql`
    query formCustomer($custCode: String) {
  formCustomer(custCode: $custCode) {
    id
    CST_CUSTLINK
    CST_CUSTCODE
    CST_GROUPCODE
    CST_NAME
    CST_CENTER
    CST_WITHDEALERS
    CST_CATEGORYDEF
    CST_CRDVERSION
    CST_RESPCODE
  }
}
    `;
/**
 * __useFormCustomerQuery__
 *
 * To run a query within a React component, call `useFormCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormCustomerQuery({
 *   variables: {
 *      custCode: // value for 'custCode'
 *   },
 * });
 */

export function useFormCustomerQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormCustomerDocument, options);
}
export function useFormCustomerLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormCustomerDocument, options);
}
export function useFormCustomerSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormCustomerDocument, options);
}
export const FormMachineCustomerDocument = gql`
    query formMachineCustomer($custCode: String) {
  formCustomer(custCode: $custCode) {
    CST_CUSTLINK
    CST_GROUPCODE
    CST_CUSTCODE
  }
}
    `;
/**
 * __useFormMachineCustomerQuery__
 *
 * To run a query within a React component, call `useFormMachineCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormMachineCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormMachineCustomerQuery({
 *   variables: {
 *      custCode: // value for 'custCode'
 *   },
 * });
 */

export function useFormMachineCustomerQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormMachineCustomerDocument, options);
}
export function useFormMachineCustomerLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormMachineCustomerDocument, options);
}
export function useFormMachineCustomerSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormMachineCustomerDocument, options);
}
export const FormInterventionOptionsDocument = gql`
    query formInterventionOptions($locale: String) {
  formInterventionOptions(locale: $locale) {
    id
    label
  }
}
    `;
/**
 * __useFormInterventionOptionsQuery__
 *
 * To run a query within a React component, call `useFormInterventionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormInterventionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormInterventionOptionsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */

export function useFormInterventionOptionsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormInterventionOptionsDocument, options);
}
export function useFormInterventionOptionsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormInterventionOptionsDocument, options);
}
export function useFormInterventionOptionsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormInterventionOptionsDocument, options);
}
export const FormUnitMileagesDocument = gql`
    query formUnitMileages($locale: String, $diagTypeId: Int) {
  formUnitMileages(locale: $locale, diagTypeId: $diagTypeId) {
    UMA_UNITMILDEF
    label
  }
}
    `;
/**
 * __useFormUnitMileagesQuery__
 *
 * To run a query within a React component, call `useFormUnitMileagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormUnitMileagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormUnitMileagesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      diagTypeId: // value for 'diagTypeId'
 *   },
 * });
 */

export function useFormUnitMileagesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormUnitMileagesDocument, options);
}
export function useFormUnitMileagesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormUnitMileagesDocument, options);
}
export function useFormUnitMileagesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormUnitMileagesDocument, options);
}
export const FormUnitConsumpDocument = gql`
    query formUnitConsump {
  formUnitConsump {
    UMO_CODE
    UMO_DESCRIPTION
  }
}
    `;
/**
 * __useFormUnitConsumpQuery__
 *
 * To run a query within a React component, call `useFormUnitConsumpQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormUnitConsumpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormUnitConsumpQuery({
 *   variables: {
 *   },
 * });
 */

export function useFormUnitConsumpQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormUnitConsumpDocument, options);
}
export function useFormUnitConsumpLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormUnitConsumpDocument, options);
}
export function useFormUnitConsumpSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormUnitConsumpDocument, options);
}
export const FormTransdevProfileAnalysesDocument = gql`
    query formTransdevProfileAnalyses($locale: String, $combType: String, $partKind: String) {
  formTransdevProfileAnalyses(
    locale: $locale
    combType: $combType
    partKind: $partKind
  ) {
    PDF_CODE
    PDF_COMBTYPE
    PDF_PARTKIND
    label
  }
}
    `;
/**
 * __useFormTransdevProfileAnalysesQuery__
 *
 * To run a query within a React component, call `useFormTransdevProfileAnalysesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormTransdevProfileAnalysesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormTransdevProfileAnalysesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      combType: // value for 'combType'
 *      partKind: // value for 'partKind'
 *   },
 * });
 */

export function useFormTransdevProfileAnalysesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormTransdevProfileAnalysesDocument, options);
}
export function useFormTransdevProfileAnalysesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormTransdevProfileAnalysesDocument, options);
}
export function useFormTransdevProfileAnalysesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormTransdevProfileAnalysesDocument, options);
}
export const FormOilsDocument = gql`
    query formOils {
  formOils {
    OIL_MAKE
    OIL_TYPE
    OIL_GRADE
    OIL_LINK
  }
}
    `;
/**
 * __useFormOilsQuery__
 *
 * To run a query within a React component, call `useFormOilsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormOilsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormOilsQuery({
 *   variables: {
 *   },
 * });
 */

export function useFormOilsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormOilsDocument, options);
}
export function useFormOilsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormOilsDocument, options);
}
export function useFormOilsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormOilsDocument, options);
}
export const FormOilGradesDocument = gql`
    query formOilGrades {
  formOilGrades
}
    `;
/**
 * __useFormOilGradesQuery__
 *
 * To run a query within a React component, call `useFormOilGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormOilGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormOilGradesQuery({
 *   variables: {
 *   },
 * });
 */

export function useFormOilGradesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormOilGradesDocument, options);
}
export function useFormOilGradesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormOilGradesDocument, options);
}
export function useFormOilGradesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormOilGradesDocument, options);
}
export const FormCanSelectOilDocument = gql`
    query formCanSelectOil($combType: String, $oilLink: Int) {
  formCanSelectOil(combType: $combType, oilLink: $oilLink) {
    warnings
  }
}
    `;
/**
 * __useFormCanSelectOilQuery__
 *
 * To run a query within a React component, call `useFormCanSelectOilQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormCanSelectOilQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormCanSelectOilQuery({
 *   variables: {
 *      combType: // value for 'combType'
 *      oilLink: // value for 'oilLink'
 *   },
 * });
 */

export function useFormCanSelectOilQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormCanSelectOilDocument, options);
}
export function useFormCanSelectOilLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormCanSelectOilDocument, options);
}
export function useFormCanSelectOilSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormCanSelectOilDocument, options);
}
export const FormCanSelectBarcodeOptionsDocument = gql`
    query formCanSelectBarcodeOptions($optionValue: String, $optionName: String, $barcode: String, $combType: String, $pdfCode: Int, $customerCode: String, $newSampleNumber: Int) {
  formCanSelectBarcodeOptions(
    optionValue: $optionValue
    optionName: $optionName
    barcode: $barcode
    combType: $combType
    pdfCode: $pdfCode
    customerCode: $customerCode
    newSampleNumber: $newSampleNumber
  ) {
    errors
    trads
  }
}
    `;
/**
 * __useFormCanSelectBarcodeOptionsQuery__
 *
 * To run a query within a React component, call `useFormCanSelectBarcodeOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormCanSelectBarcodeOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormCanSelectBarcodeOptionsQuery({
 *   variables: {
 *      optionValue: // value for 'optionValue'
 *      optionName: // value for 'optionName'
 *      barcode: // value for 'barcode'
 *      combType: // value for 'combType'
 *      pdfCode: // value for 'pdfCode'
 *      customerCode: // value for 'customerCode'
 *      newSampleNumber: // value for 'newSampleNumber'
 *   },
 * });
 */

export function useFormCanSelectBarcodeOptionsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormCanSelectBarcodeOptionsDocument, options);
}
export function useFormCanSelectBarcodeOptionsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormCanSelectBarcodeOptionsDocument, options);
}
export function useFormCanSelectBarcodeOptionsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormCanSelectBarcodeOptionsDocument, options);
}
export const FormCanSelectBarcodeDocument = gql`
    query formCanSelectBarcode($newSampleNumber: Int, $customerCode: String, $overridenCustomerCode: String, $barcode: String, $combType: String, $pdfCode: Int) {
  formCanSelectBarcode(
    newSampleNumber: $newSampleNumber
    customerCode: $customerCode
    overridenCustomerCode: $overridenCustomerCode
    barcode: $barcode
    combType: $combType
    pdfCode: $pdfCode
  ) {
    errors
    trads
    familyCode
  }
}
    `;
/**
 * __useFormCanSelectBarcodeQuery__
 *
 * To run a query within a React component, call `useFormCanSelectBarcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormCanSelectBarcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormCanSelectBarcodeQuery({
 *   variables: {
 *      newSampleNumber: // value for 'newSampleNumber'
 *      customerCode: // value for 'customerCode'
 *      overridenCustomerCode: // value for 'overridenCustomerCode'
 *      barcode: // value for 'barcode'
 *      combType: // value for 'combType'
 *      pdfCode: // value for 'pdfCode'
 *   },
 * });
 */

export function useFormCanSelectBarcodeQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormCanSelectBarcodeDocument, options);
}
export function useFormCanSelectBarcodeLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormCanSelectBarcodeDocument, options);
}
export function useFormCanSelectBarcodeSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormCanSelectBarcodeDocument, options);
}
export const FormMachineInformationDocument = gql`
    query formMachineInformation($machlink: Int, $partlink: Int, $locale: String) {
  formMachineInformation(
    machlink: $machlink
    partlink: $partlink
    locale: $locale
  ) {
    machine {
      MCH_ID
      MCH_MACHLINK
      MCH_MAK_ID
      MCH_VEHTYPE
      MCH_MACHCODE
      MAK_NAME
      MCH_OWNERSNAME
      tblMt2 {
        MT2_FULLVEHMAKE
      }
      customer {
        CST_CUSTLINK
        CST_CUSTCODE
        CST_GROUPCODE
        CST_NAME
        CST_RESPCODE
        CST_CENTER
        CST_CATEGORYDEF
        CST_WITHDEALERS
        CST_CRDVERSION
      }
    }
    part {
      PRT_ID
      PRT_PARTLINK
      PRT_PARTKIND
      PRT_PARTTYPE
      PRT_COMBTYPE
      PRT_PARTCODE
      PRT_PDFNCODE
      PRT_OILMAKEDEF
      PRT_OILTYPEDEF
      PRT_OILGRADEDEF
      PRT_CAPACITY
      PRT_FUELTYPE
      PRT_DUALFUEL
      lastSample {
        SMP_SAMPLINK
      }
      tblMt4 {
        MT4_FULLPARTMAKE
      }
    }
  }
}
    `;
/**
 * __useFormMachineInformationQuery__
 *
 * To run a query within a React component, call `useFormMachineInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormMachineInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormMachineInformationQuery({
 *   variables: {
 *      machlink: // value for 'machlink'
 *      partlink: // value for 'partlink'
 *      locale: // value for 'locale'
 *   },
 * });
 */

export function useFormMachineInformationQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormMachineInformationDocument, options);
}
export function useFormMachineInformationLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormMachineInformationDocument, options);
}
export function useFormMachineInformationSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormMachineInformationDocument, options);
}
export const FormPartInformationDocument = gql`
    query formPartInformation($partlink: Int) {
  formPartInformation(partlink: $partlink) {
    PRT_ID
    PRT_PARTLINK
    PRT_PARTTYPE
    PRT_PARTMAKE
    PRT_PARTKIND
    PRT_COMBTYPE
    machine {
      MCH_ID
      MCH_MACHLINK
      MCH_VEHTYPE
      MCH_VEHMAKE
      MCH_OWNERSNAME
    }
  }
}
    `;
/**
 * __useFormPartInformationQuery__
 *
 * To run a query within a React component, call `useFormPartInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormPartInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormPartInformationQuery({
 *   variables: {
 *      partlink: // value for 'partlink'
 *   },
 * });
 */

export function useFormPartInformationQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormPartInformationDocument, options);
}
export function useFormPartInformationLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormPartInformationDocument, options);
}
export function useFormPartInformationSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormPartInformationDocument, options);
}
export const FormSampleInformationDocument = gql`
    query formSampleInformation($samplink: Int) {
  formSampleInformation(samplink: $samplink) {
    SMP_DTSAMPLING
    SMP_TOTMILEAGE
    SMP_UNITMILEAGE
    SMP_SUMPMILEAGE
    SMP_OILMAKE
    SMP_OILTYPE
    SMP_OILGRADE
    SMP_OILCONSUMP
    SMP_OILCONSUMP_UNIT
    SMP_SMPMT
    SMP_DIAGTYPE
  }
}
    `;
/**
 * __useFormSampleInformationQuery__
 *
 * To run a query within a React component, call `useFormSampleInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSampleInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSampleInformationQuery({
 *   variables: {
 *      samplink: // value for 'samplink'
 *   },
 * });
 */

export function useFormSampleInformationQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormSampleInformationDocument, options);
}
export function useFormSampleInformationLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormSampleInformationDocument, options);
}
export function useFormSampleInformationSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormSampleInformationDocument, options);
}
export const FormCustomersDocument = gql`
    query formCustomers {
  formCustomers {
    CST_CUSTLINK
    CST_CUSTCODE
    CST_NAME
    CST_EMAIL_SALES_ORDER
  }
}
    `;
/**
 * __useFormCustomersQuery__
 *
 * To run a query within a React component, call `useFormCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormCustomersQuery({
 *   variables: {
 *   },
 * });
 */

export function useFormCustomersQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FormCustomersDocument, options);
}
export function useFormCustomersLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FormCustomersDocument, options);
}
export function useFormCustomersSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FormCustomersDocument, options);
}
export const FormCreateSampleDocument = gql`
    mutation formCreateSample($machine: FormCreateSampleMachineInput, $part: FormCreateSamplePartInput, $sample: FormCreateSampleSampleInput) {
  formCreateSample(machine: $machine, part: $part, sample: $sample)
}
    `;

/**
 * __useFormCreateSampleMutation__
 *
 * To run a mutation, you first call `useFormCreateSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormCreateSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formCreateSampleMutation, { data, loading, error }] = useFormCreateSampleMutation({
 *   variables: {
 *      machine: // value for 'machine'
 *      part: // value for 'part'
 *      sample: // value for 'sample'
 *   },
 * });
 */
export function useFormCreateSampleMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(FormCreateSampleDocument, options);
}
export const FormUpdateSampleDocument = gql`
    mutation formUpdateSample($id: Int, $sample: FormCreateSampleSampleInput) {
  formUpdateSample(id: $id, sample: $sample)
}
    `;

/**
 * __useFormUpdateSampleMutation__
 *
 * To run a mutation, you first call `useFormUpdateSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormUpdateSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formUpdateSampleMutation, { data, loading, error }] = useFormUpdateSampleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sample: // value for 'sample'
 *   },
 * });
 */
export function useFormUpdateSampleMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(FormUpdateSampleDocument, options);
}
export const UpdatePartMachineSampleDocument = gql`
    mutation updatePartMachineSample($data: PartMachineFormInput) {
  updatePartMachineSample(data: $data) {
    machineUpd {
      MACHLINK
    }
    partUpd {
      PARTLINK
    }
  }
}
    `;

/**
 * __useUpdatePartMachineSampleMutation__
 *
 * To run a mutation, you first call `useUpdatePartMachineSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartMachineSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartMachineSampleMutation, { data, loading, error }] = useUpdatePartMachineSampleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePartMachineSampleMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(UpdatePartMachineSampleDocument, options);
}
export const KitPreviousOrdersDocument = gql`
    query kitPreviousOrders($customerCode: String) {
  kitPreviousOrders(customerCode: $customerCode) {
    maximumSamples
    lastAddress
    lastCompanyName
    lastContactName
    lastPhone
    emailADV
    contactEmail
    products {
      PRF_FAMILYCODE
      PRF_SHORTNAME
      PRF_NAME
      PRF_SORT
      PRF_QUANTITY
      PRF_DEFAULT_CRDVERSION
      PRF_DEFAULT_ANAL_SCHEMA
      PRF_USE_OIL_ANAL_SCHEMA
      PRF_DIAGTYPE
      PRF_PREFIX_ORDERS
      PRF_OPTION
      PRF_FAMILY_ALLOWED
      PRF_PDFN_ALLOWED
      PRF_ARTICLE
      PRF_PRICE
      PRF_NAME_LONG
      PRF_LINKED_FAMILYCODE
      PRF_LINKED_QUANTITY
      PRF_PREPAID
      PRF_POSTPAID
      PRF_LETTER_BARCODE
      PRF_LOGO_FILENAME
    }
  }
}
    `;
/**
 * __useKitPreviousOrdersQuery__
 *
 * To run a query within a React component, call `useKitPreviousOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitPreviousOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitPreviousOrdersQuery({
 *   variables: {
 *      customerCode: // value for 'customerCode'
 *   },
 * });
 */

export function useKitPreviousOrdersQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(KitPreviousOrdersDocument, options);
}
export function useKitPreviousOrdersLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(KitPreviousOrdersDocument, options);
}
export function useKitPreviousOrdersSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(KitPreviousOrdersDocument, options);
}
export const FetchKitRequestsDocument = gql`
    query fetchKitRequests {
  fetchKitRequests {
    NOR_EMAIL_ADV
    totalKits
    totalSamples
    NOR_REQUEST_DATE
    NOR_REQUEST_ORIGIN
    customer {
      CST_NAME
    }
  }
}
    `;
/**
 * __useFetchKitRequestsQuery__
 *
 * To run a query within a React component, call `useFetchKitRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchKitRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchKitRequestsQuery({
 *   variables: {
 *   },
 * });
 */

export function useFetchKitRequestsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FetchKitRequestsDocument, options);
}
export function useFetchKitRequestsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FetchKitRequestsDocument, options);
}
export function useFetchKitRequestsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FetchKitRequestsDocument, options);
}
export const SendKitRequestDocument = gql`
    mutation sendKitRequest($request: kitRequestInput!) {
  sendKitRequest(request: $request)
}
    `;

/**
 * __useSendKitRequestMutation__
 *
 * To run a mutation, you first call `useSendKitRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendKitRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendKitRequestMutation, { data, loading, error }] = useSendKitRequestMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSendKitRequestMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(SendKitRequestDocument, options);
}
export const AllLocalizedInfosDocument = gql`
    query allLocalizedInfos {
  allLocalizedInfos {
    id
    key
    locale
    text
  }
}
    `;
/**
 * __useAllLocalizedInfosQuery__
 *
 * To run a query within a React component, call `useAllLocalizedInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLocalizedInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLocalizedInfosQuery({
 *   variables: {
 *   },
 * });
 */

export function useAllLocalizedInfosQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(AllLocalizedInfosDocument, options);
}
export function useAllLocalizedInfosLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(AllLocalizedInfosDocument, options);
}
export function useAllLocalizedInfosSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(AllLocalizedInfosDocument, options);
}
export const LocalizedInfosDocument = gql`
    query localizedInfos($locale: UserLocale!) {
  localizedInfos(locale: $locale) {
    id
    key
    locale
    text
  }
}
    `;
/**
 * __useLocalizedInfosQuery__
 *
 * To run a query within a React component, call `useLocalizedInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalizedInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalizedInfosQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */

export function useLocalizedInfosQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(LocalizedInfosDocument, options);
}
export function useLocalizedInfosLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(LocalizedInfosDocument, options);
}
export function useLocalizedInfosSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(LocalizedInfosDocument, options);
}
export const UpdateLocalizedInfosDocument = gql`
    mutation updateLocalizedInfos($localizedInfos: [LocalizedInfoInput!]!) {
  updateLocalizedInfos(localizedInfos: $localizedInfos) {
    id
    key
    locale
    text
  }
}
    `;

/**
 * __useUpdateLocalizedInfosMutation__
 *
 * To run a mutation, you first call `useUpdateLocalizedInfosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocalizedInfosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocalizedInfosMutation, { data, loading, error }] = useUpdateLocalizedInfosMutation({
 *   variables: {
 *      localizedInfos: // value for 'localizedInfos'
 *   },
 * });
 */
export function useUpdateLocalizedInfosMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(UpdateLocalizedInfosDocument, options);
}
export const MachinesDocument = gql`
    query machines($machinePartSearch: String, $customerName: String, $statuses: [SampleStatus!], $limit: Int!, $from: Date, $to: Date) {
  machines(
    machinePartSearch: $machinePartSearch
    customerName: $customerName
    statuses: $statuses
    limit: $limit
    from: $from
    to: $to
  ) {
    machines {
      ...machineFields
    }
    count
  }
}
    ${MachineFieldsFragmentDoc}`;
/**
 * __useMachinesQuery__
 *
 * To run a query within a React component, call `useMachinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMachinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMachinesQuery({
 *   variables: {
 *      machinePartSearch: // value for 'machinePartSearch'
 *      customerName: // value for 'customerName'
 *      statuses: // value for 'statuses'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */

export function useMachinesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(MachinesDocument, options);
}
export function useMachinesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(MachinesDocument, options);
}
export function useMachinesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(MachinesDocument, options);
}
export const MachineDocument = gql`
    query machine($id: Int!) {
  machine(id: $id) {
    ...machineFields
  }
}
    ${MachineFieldsFragmentDoc}`;
/**
 * __useMachineQuery__
 *
 * To run a query within a React component, call `useMachineQuery` and pass it any options that fit your needs.
 * When your component renders, `useMachineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMachineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */

export function useMachineQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(MachineDocument, options);
}
export function useMachineLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(MachineDocument, options);
}
export function useMachineSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(MachineDocument, options);
}
export const CustomerAutocompleteDocument = gql`
    query customerAutocomplete($search: String) {
  customerAutocomplete(search: $search) {
    id
    CST_CUSTCODE
    CST_PHONE
    CST_NAME
    CST_EMAIL
  }
}
    `;
/**
 * __useCustomerAutocompleteQuery__
 *
 * To run a query within a React component, call `useCustomerAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAutocompleteQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */

export function useCustomerAutocompleteQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(CustomerAutocompleteDocument, options);
}
export function useCustomerAutocompleteLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(CustomerAutocompleteDocument, options);
}
export function useCustomerAutocompleteSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(CustomerAutocompleteDocument, options);
}
export const TechnicalDelegateDocument = gql`
    query technicalDelegate {
  technicalDelegate {
    id
    CST_CUSTCODE
    CST_PHONE
    CST_NAME
    CST_EMAIL
  }
}
    `;
/**
 * __useTechnicalDelegateQuery__
 *
 * To run a query within a React component, call `useTechnicalDelegateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnicalDelegateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnicalDelegateQuery({
 *   variables: {
 *   },
 * });
 */

export function useTechnicalDelegateQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(TechnicalDelegateDocument, options);
}
export function useTechnicalDelegateLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(TechnicalDelegateDocument, options);
}
export function useTechnicalDelegateSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(TechnicalDelegateDocument, options);
}
export const MachineAutocompleteDocument = gql`
    query machineAutocomplete($search: String) {
  machineAutocomplete(search: $search) {
    MCH_MACHLINK
    MCH_MACHCODE
    MCH_ID
    MCH_OWNERSNAME
    MCH_VEHTYPE
  }
}
    `;
/**
 * __useMachineAutocompleteQuery__
 *
 * To run a query within a React component, call `useMachineAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useMachineAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMachineAutocompleteQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */

export function useMachineAutocompleteQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(MachineAutocompleteDocument, options);
}
export function useMachineAutocompleteLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(MachineAutocompleteDocument, options);
}
export function useMachineAutocompleteSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(MachineAutocompleteDocument, options);
}
export const MachinePartsDocument = gql`
    query machineParts($machlink: Int!) {
  machineParts(machlink: $machlink) {
    PRT_PARTLINK
    PRT_PARTCODE
    PRT_ID
    PRT_PARTTYPE
    PRT_PDFNCODE
    tblMt4 {
      MT4_FULLPARTMAKE
    }
    lastSample {
      SMP_DTCARD
    }
  }
}
    `;
/**
 * __useMachinePartsQuery__
 *
 * To run a query within a React component, call `useMachinePartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMachinePartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMachinePartsQuery({
 *   variables: {
 *      machlink: // value for 'machlink'
 *   },
 * });
 */

export function useMachinePartsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(MachinePartsDocument, options);
}
export function useMachinePartsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(MachinePartsDocument, options);
}
export function useMachinePartsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(MachinePartsDocument, options);
}
export const MachinesCsvExtractionDocument = gql`
    mutation machinesCsvExtraction($machinePartSearch: String, $customerName: String, $statuses: [SampleStatus!], $from: Date, $to: Date) {
  machinesCsvExtraction(
    machinePartSearch: $machinePartSearch
    customerName: $customerName
    statuses: $statuses
    from: $from
    to: $to
  )
}
    `;

/**
 * __useMachinesCsvExtractionMutation__
 *
 * To run a mutation, you first call `useMachinesCsvExtractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMachinesCsvExtractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [machinesCsvExtractionMutation, { data, loading, error }] = useMachinesCsvExtractionMutation({
 *   variables: {
 *      machinePartSearch: // value for 'machinePartSearch'
 *      customerName: // value for 'customerName'
 *      statuses: // value for 'statuses'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useMachinesCsvExtractionMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(MachinesCsvExtractionDocument, options);
}
export const MachinesXlsxExtractionDocument = gql`
    mutation machinesXlsxExtraction($machinePartSearch: String, $customerName: String, $statuses: [SampleStatus!], $from: Date, $to: Date) {
  machinesXlsxExtraction(
    machinePartSearch: $machinePartSearch
    customerName: $customerName
    statuses: $statuses
    from: $from
    to: $to
  )
}
    `;

/**
 * __useMachinesXlsxExtractionMutation__
 *
 * To run a mutation, you first call `useMachinesXlsxExtractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMachinesXlsxExtractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [machinesXlsxExtractionMutation, { data, loading, error }] = useMachinesXlsxExtractionMutation({
 *   variables: {
 *      machinePartSearch: // value for 'machinePartSearch'
 *      customerName: // value for 'customerName'
 *      statuses: // value for 'statuses'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useMachinesXlsxExtractionMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(MachinesXlsxExtractionDocument, options);
}
export const MachinesPdfExtractionDocument = gql`
    mutation machinesPdfExtraction($machinePartSearch: String, $customerName: String, $statuses: [SampleStatus!], $from: Date, $to: Date) {
  machinesPdfExtraction(
    machinePartSearch: $machinePartSearch
    customerName: $customerName
    statuses: $statuses
    from: $from
    to: $to
  )
}
    `;

/**
 * __useMachinesPdfExtractionMutation__
 *
 * To run a mutation, you first call `useMachinesPdfExtractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMachinesPdfExtractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [machinesPdfExtractionMutation, { data, loading, error }] = useMachinesPdfExtractionMutation({
 *   variables: {
 *      machinePartSearch: // value for 'machinePartSearch'
 *      customerName: // value for 'customerName'
 *      statuses: // value for 'statuses'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useMachinesPdfExtractionMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(MachinesPdfExtractionDocument, options);
}
export const MachinesQrCodePdfDocument = gql`
    mutation machinesQrCodePdf($customerCode: String, $machineCode: String, $qrCode: String) {
  machinesQrCodePdf(
    customerCode: $customerCode
    machineCode: $machineCode
    qrCode: $qrCode
  )
}
    `;

/**
 * __useMachinesQrCodePdfMutation__
 *
 * To run a mutation, you first call `useMachinesQrCodePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMachinesQrCodePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [machinesQrCodePdfMutation, { data, loading, error }] = useMachinesQrCodePdfMutation({
 *   variables: {
 *      customerCode: // value for 'customerCode'
 *      machineCode: // value for 'machineCode'
 *      qrCode: // value for 'qrCode'
 *   },
 * });
 */
export function useMachinesQrCodePdfMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(MachinesQrCodePdfDocument, options);
}
export const NewSamplesDocument = gql`
    query newSamples($sampleLabel: String, $customerCode: String, $limit: Int!) {
  newSamples(
    sampleLabel: $sampleLabel
    customerCode: $customerCode
    limit: $limit
  ) {
    newSamples {
      ...newSampleFields
    }
    count
  }
}
    ${NewSampleFieldsFragmentDoc}`;
/**
 * __useNewSamplesQuery__
 *
 * To run a query within a React component, call `useNewSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewSamplesQuery({
 *   variables: {
 *      sampleLabel: // value for 'sampleLabel'
 *      customerCode: // value for 'customerCode'
 *      limit: // value for 'limit'
 *   },
 * });
 */

export function useNewSamplesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(NewSamplesDocument, options);
}
export function useNewSamplesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(NewSamplesDocument, options);
}
export function useNewSamplesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(NewSamplesDocument, options);
}
export const SampleNewSampleFormDocument = gql`
    query sampleNewSampleForm($samplink: Int!) {
  sampleNewSampleForm(samplink: $samplink) {
    ...newSampleFields
  }
}
    ${NewSampleFieldsFragmentDoc}`;
/**
 * __useSampleNewSampleFormQuery__
 *
 * To run a query within a React component, call `useSampleNewSampleFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleNewSampleFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleNewSampleFormQuery({
 *   variables: {
 *      samplink: // value for 'samplink'
 *   },
 * });
 */

export function useSampleNewSampleFormQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SampleNewSampleFormDocument, options);
}
export function useSampleNewSampleFormLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SampleNewSampleFormDocument, options);
}
export function useSampleNewSampleFormSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SampleNewSampleFormDocument, options);
}
export const DeleteNewSamplesDocument = gql`
    mutation deleteNewSamples($id: Int!) {
  deleteNewSamples(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteNewSamplesMutation__
 *
 * To run a mutation, you first call `useDeleteNewSamplesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewSamplesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewSamplesMutation, { data, loading, error }] = useDeleteNewSamplesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNewSamplesMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(DeleteNewSamplesDocument, options);
}
export const OffersDocument = gql`
    query offers {
  offers
}
    `;
/**
 * __useOffersQuery__
 *
 * To run a query within a React component, call `useOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffersQuery({
 *   variables: {
 *   },
 * });
 */

export function useOffersQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(OffersDocument, options);
}
export function useOffersLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(OffersDocument, options);
}
export function useOffersSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(OffersDocument, options);
}
export const PrSamplesDocument = gql`
    query prSamples($sampleLabel: String, $customerCode: String, $limit: Int!) {
  prSamples(sampleLabel: $sampleLabel, customerCode: $customerCode, limit: $limit) {
    prSamples {
      id
      status
      SMP_ALFALABEL
      SMP_DTSAMPLING
      SMP_DTRECEPTION
      SMP_DTCARD
      SMP_ICPROD
      SMP_PARTLINK
      SMP_SEQNR
      SMP_DIAGTYPE
      part {
        id
        PRT_PARTLINK
        PRT_PARTCODE
        PRT_ID
        PRT_PARTTYPE
        PRT_PDFNCODE
        PRT_COMBTYPE
        tblMt4 {
          MT4_FULLPARTMAKE
        }
        machine {
          id
          MCH_MACHLINK
          MCH_MACHCODE
          MCH_ID
          MCH_OWNERSNAME
          MCH_VEHTYPE
          tblMt2 {
            MT2_FULLVEHMAKE
          }
          customer {
            CST_CUSTCODE
            CST_GROUPCODE
          }
        }
      }
    }
    count
  }
}
    `;
/**
 * __usePrSamplesQuery__
 *
 * To run a query within a React component, call `usePrSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrSamplesQuery({
 *   variables: {
 *      sampleLabel: // value for 'sampleLabel'
 *      customerCode: // value for 'customerCode'
 *      limit: // value for 'limit'
 *   },
 * });
 */

export function usePrSamplesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(PrSamplesDocument, options);
}
export function usePrSamplesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(PrSamplesDocument, options);
}
export function usePrSamplesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(PrSamplesDocument, options);
}
export const SamplesToValidateDocument = gql`
    query samplesToValidate($limit: Int!) {
  samplesToValidate(limit: $limit) {
    samples {
      hasTboComment
      ...sampleFields
    }
    count
  }
}
    ${SampleFieldsFragmentDoc}`;
/**
 * __useSamplesToValidateQuery__
 *
 * To run a query within a React component, call `useSamplesToValidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSamplesToValidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSamplesToValidateQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */

export function useSamplesToValidateQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SamplesToValidateDocument, options);
}
export function useSamplesToValidateLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SamplesToValidateDocument, options);
}
export function useSamplesToValidateSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SamplesToValidateDocument, options);
}
export const SampleToValidateDocument = gql`
    query sampleToValidate($id: Int!) {
  sampleToValidate(id: $id) {
    id
    canEdit
    status
    hasTboComment
    canSeeSampleDetails
    SMP_DIAGTYPE
    SMP_ALFALABEL
    SMP_DTSAMPLING
    SMP_DTCARD
    SMP_ICPROD
    SMP_PARTLINK
    SMP_SEQNR
    SMP_DIAGTYPE
    SMP_INTERVENTION
    SMP_ANALYSIS_NAME
    SMP_UNITMILEAGE
    SMP_SMPMT
    SMP_TOTMILEAGE
    SMP_SUMPMILEAGE
    SMP_DTRECEPTION
    part {
      id
      PRT_PARTLINK
      PRT_PARTCODE
      PRT_ID
      PRT_PARTTYPE
      PRT_PDFNCODE
      PRT_CAPACITY
      PRT_COMBTYPE
      PRT_PARTKIND
      PRT_PARTMAKE
      euroCode {
        EUR_DESCRIPTION
      }
      tblMt4 {
        MT4_FULLPARTMAKE
      }
      machine {
        id
        MCH_MACHLINK
        MCH_MACHCODE
        MCH_ID
        MCH_VEHMAKE
        MCH_OWNERSNAME
        MCH_VEHTYPE
        MCH_MAK_ID
        MCH_MACHINFO3
        MCH_MACHINFO5
        MCH_MACHINFO6
        tblMt4 {
          MT4_FULLPARTMAKE
        }
        tblMt2 {
          MT2_FULLVEHMAKE
        }
        customer {
          id
          CST_CUSTLINK
          CST_NAME
          CST_COUNTRYCODE
          CST_LOCALITY
          CST_CUSTCODE
          CST_CENTER
          CST_GROUPCODE
          CST_RESPCODE
          technicalDelegate {
            CST_EMAIL
            CST_CUSTCODE
          }
        }
      }
    }
    SMP_DTCARD
    SMP_OILMAKE
    SMP_OILTYPE
    SMP_OILGRADE
    SMP_ALFALABEL_OPT1
    SMP_ALFALABEL_OPT2
    SMP_ALFALABEL_OPT3
    SMP_ALFALABEL_OPT4
    SMP_ALFALABEL_OPT5
    SMP_DEALERCODE
    SMP_DIAGTYPE
    SMP_ICPROD
    SMP_OILCONSUMP
    SMP_OILCONSUMP_UNIT
    SMP_UNITMILEAGE_LUBTOT
    hasSampleBarcode
    dealer {
      CST_NAME
      CST_COUNTRYCODE
      CST_LOCALITY
    }
    oilId {
      OIL_LINK
      OIL_MAKE
      OIL_MAKE_OUTPUT
      OIL_TYPE_OUTPUT
      OIL_GRADE
      OIL_TYPE
    }
    isValidate
    logoFilename
  }
}
    `;
/**
 * __useSampleToValidateQuery__
 *
 * To run a query within a React component, call `useSampleToValidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleToValidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleToValidateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */

export function useSampleToValidateQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SampleToValidateDocument, options);
}
export function useSampleToValidateLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SampleToValidateDocument, options);
}
export function useSampleToValidateSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SampleToValidateDocument, options);
}
export const SampleDetailsDocument = gql`
    query sampleDetails($id: Int!, $locale: String!) {
  sampleDetails(id: $id, locale: $locale) {
    sample {
      id
      SMP_DTSAMPLING
      SMP_TOTMILEAGE
      SMP_UNITMILEAGE
      SMP_ALFALABEL
      SMP_DTCARD
      SMP_SUMPMILEAGE
      SMP_UNITMILEAGE
      SMP_CARDCOLOUR
      SMP_COMBTYPE
      SMP_PARTMAKE
      SMP_PARTTYPE
      SMP_KREAL
      SMP_SMPMT
      SMP_RUNIN
      SMP_OILCONSUMP
      SMP_REDIR_TS
      SMP_CUCOOLER
      SMP_HOTLINESIT
      SMP_WEARCOEFF
      SMP_SULF_ASH
      SMP_VI_INDEX
      SMP_PARTKIND
      SMP_CAPSPROD
      SMP_WCCOLOUR
      status
      part {
        id
        machine {
          id
          customer {
            id
            CST_NAME
          }
        }
      }
    }
    language
    cc
    wear {
      title
      elements {
        label
        SRES_ELEMCODE
        SRES_MEASURED
        SRES_A5OUTPUT
        SRES_REFER
        SRES_REJECTED
        status
      }
    }
    contamination {
      title
      elements {
        label
        SRES_ELEMCODE
        SRES_MEASURED
        SRES_ELCOLOUR
        SRES_A5OUTPUT
        SRES_REFER
        SRES_REJECTED
        status
        value
      }
    }
    other {
      title
      elements {
        label
        SRES_ELEMCODE
        SRES_MEASURED
        SRES_ELCOLOUR
        SRES_A5OUTPUT
        SRES_REFER
        SRES_REJECTED
        status
      }
    }
    oil {
      title
      elements {
        label
        SRES_ELEMCODE
        SRES_ELCOLOUR
        SRES_MEASURED
        SRES_A5OUTPUT
        SRES_REFER
        SRES_REJECTED
        status
        unit
      }
    }
    additivien {
      title
      elements {
        label
        SRES_MEASURED
      }
    }
  }
}
    `;
/**
 * __useSampleDetailsQuery__
 *
 * To run a query within a React component, call `useSampleDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */

export function useSampleDetailsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SampleDetailsDocument, options);
}
export function useSampleDetailsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SampleDetailsDocument, options);
}
export function useSampleDetailsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SampleDetailsDocument, options);
}
export const SampleToValidateCommentsDocument = gql`
    query sampleToValidateComments($id: Int!, $locale: String!) {
  sampleToValidateComments(id: $id, locale: $locale) {
    yourInformation
    personalTboComments {
      STC_COMMENT
      STC_COMMENT_FREE
      STC_SAMPLINK
    }
    tboComments {
      STC_COMMENT
      STC_COMMENT_FREE
      STC_SAMPLINK
    }
    diagnosisComments {
      CC_CODE
      TXT_TEXT
    }
    remarks {
      CC_CODE
      TXT_TEXT
    }
    comments {
      CC_CODE
      CCD_PRCODE
      TXT_TEXT
    }
  }
}
    `;
/**
 * __useSampleToValidateCommentsQuery__
 *
 * To run a query within a React component, call `useSampleToValidateCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleToValidateCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleToValidateCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */

export function useSampleToValidateCommentsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SampleToValidateCommentsDocument, options);
}
export function useSampleToValidateCommentsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SampleToValidateCommentsDocument, options);
}
export function useSampleToValidateCommentsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SampleToValidateCommentsDocument, options);
}
export const SamplesDocument = gql`
    query samples($from: Date!, $to: Date!, $customerName: String, $machinePartSearch: String, $sampleLabel: String, $awaitingFeedback: Boolean, $offer: String, $oil: String, $dealersCodes: [String], $onlyLastSample: Boolean, $statuses: [SampleStatus!], $limit: Int!) {
  samples(
    from: $from
    to: $to
    customerName: $customerName
    machinePartSearch: $machinePartSearch
    sampleLabel: $sampleLabel
    awaitingFeedback: $awaitingFeedback
    offer: $offer
    oil: $oil
    dealersCodes: $dealersCodes
    onlyLastSample: $onlyLastSample
    statuses: $statuses
    limit: $limit
  ) {
    samples {
      ...sampleFields
    }
    count
  }
}
    ${SampleFieldsFragmentDoc}`;
/**
 * __useSamplesQuery__
 *
 * To run a query within a React component, call `useSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSamplesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      customerName: // value for 'customerName'
 *      machinePartSearch: // value for 'machinePartSearch'
 *      sampleLabel: // value for 'sampleLabel'
 *      awaitingFeedback: // value for 'awaitingFeedback'
 *      offer: // value for 'offer'
 *      oil: // value for 'oil'
 *      dealersCodes: // value for 'dealersCodes'
 *      onlyLastSample: // value for 'onlyLastSample'
 *      statuses: // value for 'statuses'
 *      limit: // value for 'limit'
 *   },
 * });
 */

export function useSamplesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SamplesDocument, options);
}
export function useSamplesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SamplesDocument, options);
}
export function useSamplesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SamplesDocument, options);
}
export const SamplesResultsDocument = gql`
    query samplesResults($id: Int!, $locale: String!, $limit: Int!) {
  samplesResults(id: $id, locale: $locale, limit: $limit) {
    data {
      id
      status
      SMP_TOTMILEAGE
      SMP_ANALYSIS_NAME
      SMP_DIAGTYPE
      SMP_CARDCOLOUR
      SMP_DTSAMPLING
      SMP_ALFALABEL
      SMP_OILMAKE
      SMP_OILTYPE
      SMP_OILGRADE
      SMP_SEQNR
      SMP_SMPMT
      SMP_DTRECEPTION
      SMP_LBM_PORT
      SMP_LBM_PURIERFILTER
      SMP_UNITMILEAGE
      SMP_UNITMILEAGE_LUBTOT
      SMP_SUMPMILEAGE
      SMP_OILCONSUMP
      SMP_OILCONSUMP_UNIT
      SMP_DRAIN_FO_S
      SMP_DRAIN_ENG_LOAD
      SMP_DRAIN_LOFR_G_KWH
      SMP_CONC_MEASURED
      SMP_CONC_PRODUCT
      UNITMILEAGE_TXT
      UNITMILEAGE_LUBTOT_TXT
      oilId {
        OIL_LINK
        OIL_MAKE
        OIL_TYPE_OUTPUT
        OIL_MAKE_OUTPUT
        OIL_GRADE
      }
    }
    results {
      groupLabel
      rank
      elements {
        order
        label
        unit
        values {
          label
          status
          samplink
          images {
            seqNumber
            type
            typeLnf
            kind
          }
        }
      }
    }
  }
}
    `;
/**
 * __useSamplesResultsQuery__
 *
 * To run a query within a React component, call `useSamplesResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSamplesResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSamplesResultsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *   },
 * });
 */

export function useSamplesResultsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SamplesResultsDocument, options);
}
export function useSamplesResultsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SamplesResultsDocument, options);
}
export function useSamplesResultsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SamplesResultsDocument, options);
}
export const SampleBase64BarcodePhotoDocument = gql`
    query sampleBase64BarcodePhoto($samplink: Int!) {
  sampleBase64BarcodePhoto(samplink: $samplink)
}
    `;
/**
 * __useSampleBase64BarcodePhotoQuery__
 *
 * To run a query within a React component, call `useSampleBase64BarcodePhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleBase64BarcodePhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleBase64BarcodePhotoQuery({
 *   variables: {
 *      samplink: // value for 'samplink'
 *   },
 * });
 */

export function useSampleBase64BarcodePhotoQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SampleBase64BarcodePhotoDocument, options);
}
export function useSampleBase64BarcodePhotoLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SampleBase64BarcodePhotoDocument, options);
}
export function useSampleBase64BarcodePhotoSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SampleBase64BarcodePhotoDocument, options);
}
export const SampleBase64PhotoDocument = gql`
    query sampleBase64Photo($samplink: Int!, $seqNumber: Int!) {
  sampleBase64Photo(samplink: $samplink, seqNumber: $seqNumber)
}
    `;
/**
 * __useSampleBase64PhotoQuery__
 *
 * To run a query within a React component, call `useSampleBase64PhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleBase64PhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleBase64PhotoQuery({
 *   variables: {
 *      samplink: // value for 'samplink'
 *      seqNumber: // value for 'seqNumber'
 *   },
 * });
 */

export function useSampleBase64PhotoQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SampleBase64PhotoDocument, options);
}
export function useSampleBase64PhotoLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SampleBase64PhotoDocument, options);
}
export function useSampleBase64PhotoSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SampleBase64PhotoDocument, options);
}
export const SampleBase64ExtraPageDocument = gql`
    query sampleBase64ExtraPage($samplink: Int!, $seqNumber: Int!, $type: String!, $typeLnf: String!) {
  sampleBase64ExtraPage(
    samplink: $samplink
    seqNumber: $seqNumber
    type: $type
    typeLnf: $typeLnf
  )
}
    `;
/**
 * __useSampleBase64ExtraPageQuery__
 *
 * To run a query within a React component, call `useSampleBase64ExtraPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleBase64ExtraPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleBase64ExtraPageQuery({
 *   variables: {
 *      samplink: // value for 'samplink'
 *      seqNumber: // value for 'seqNumber'
 *      type: // value for 'type'
 *      typeLnf: // value for 'typeLnf'
 *   },
 * });
 */

export function useSampleBase64ExtraPageQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SampleBase64ExtraPageDocument, options);
}
export function useSampleBase64ExtraPageLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SampleBase64ExtraPageDocument, options);
}
export function useSampleBase64ExtraPageSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SampleBase64ExtraPageDocument, options);
}
export const SampleCapsDocument = gql`
    query sampleCaps($samplink: Int!, $locale: String!) {
  sampleCaps(locale: $locale, samplink: $samplink) {
    CCD_HIERARCHY
    CCD_DISTRIBUTION
    CCD_PRCODE
    CCD_FATHER
    TXT_TEXT
  }
}
    `;
/**
 * __useSampleCapsQuery__
 *
 * To run a query within a React component, call `useSampleCapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSampleCapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSampleCapsQuery({
 *   variables: {
 *      samplink: // value for 'samplink'
 *      locale: // value for 'locale'
 *   },
 * });
 */

export function useSampleCapsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SampleCapsDocument, options);
}
export function useSampleCapsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SampleCapsDocument, options);
}
export function useSampleCapsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SampleCapsDocument, options);
}
export const FollowingSamplesDocument = gql`
    query followingSamples($samplink: Int!) {
  followingSamples(samplink: $samplink) {
    SMP_SAMPLINK
  }
}
    `;
/**
 * __useFollowingSamplesQuery__
 *
 * To run a query within a React component, call `useFollowingSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowingSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowingSamplesQuery({
 *   variables: {
 *      samplink: // value for 'samplink'
 *   },
 * });
 */

export function useFollowingSamplesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(FollowingSamplesDocument, options);
}
export function useFollowingSamplesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(FollowingSamplesDocument, options);
}
export function useFollowingSamplesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(FollowingSamplesDocument, options);
}
export const SamplesFeedbackDocument = gql`
    query samplesFeedback($partLink: Int!, $seqnr: Int!, $limit: Int!) {
  samplesFeedback(partLink: $partLink, seqnr: $seqnr, limit: $limit) {
    SMP_DTSAMPLING
    SMP_ALFALABEL
    SMP_TOTMILEAGE
    SMP_SAMPCATEG
    SMP_ANALYSIS_NAME
    SMP_DIAGTYPE
    status
    part {
      machine {
        customer {
          CST_CENTER
        }
      }
    }
  }
}
    `;
/**
 * __useSamplesFeedbackQuery__
 *
 * To run a query within a React component, call `useSamplesFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useSamplesFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSamplesFeedbackQuery({
 *   variables: {
 *      partLink: // value for 'partLink'
 *      seqnr: // value for 'seqnr'
 *      limit: // value for 'limit'
 *   },
 * });
 */

export function useSamplesFeedbackQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(SamplesFeedbackDocument, options);
}
export function useSamplesFeedbackLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(SamplesFeedbackDocument, options);
}
export function useSamplesFeedbackSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(SamplesFeedbackDocument, options);
}
export const WebregistrationRatioDocument = gql`
    query webregistrationRatio($from: Date!, $to: Date!) {
  webregistrationRatio(from: $from, to: $to) {
    webregistration
    newsamples
    totalSamples
  }
}
    `;
/**
 * __useWebregistrationRatioQuery__
 *
 * To run a query within a React component, call `useWebregistrationRatioQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebregistrationRatioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebregistrationRatioQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */

export function useWebregistrationRatioQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(WebregistrationRatioDocument, options);
}
export function useWebregistrationRatioLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(WebregistrationRatioDocument, options);
}
export function useWebregistrationRatioSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(WebregistrationRatioDocument, options);
}
export const SamplesXlsxDocument = gql`
    mutation samplesXlsx($samplinks: [Int!]!) {
  samplesXlsx(samplinks: $samplinks)
}
    `;

/**
 * __useSamplesXlsxMutation__
 *
 * To run a mutation, you first call `useSamplesXlsxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSamplesXlsxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [samplesXlsxMutation, { data, loading, error }] = useSamplesXlsxMutation({
 *   variables: {
 *      samplinks: // value for 'samplinks'
 *   },
 * });
 */
export function useSamplesXlsxMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(SamplesXlsxDocument, options);
}
export const SamplesCsvDocument = gql`
    mutation samplesCsv($samplinks: [Int!]!) {
  samplesCsv(samplinks: $samplinks)
}
    `;

/**
 * __useSamplesCsvMutation__
 *
 * To run a mutation, you first call `useSamplesCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSamplesCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [samplesCsvMutation, { data, loading, error }] = useSamplesCsvMutation({
 *   variables: {
 *      samplinks: // value for 'samplinks'
 *   },
 * });
 */
export function useSamplesCsvMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(SamplesCsvDocument, options);
}
export const SamplesPdfDocument = gql`
    mutation samplesPdf($samplinks: [Int!]!, $email: String!, $locale: String!) {
  samplesPdf(samplinks: $samplinks, email: $email, locale: $locale)
}
    `;

/**
 * __useSamplesPdfMutation__
 *
 * To run a mutation, you first call `useSamplesPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSamplesPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [samplesPdfMutation, { data, loading, error }] = useSamplesPdfMutation({
 *   variables: {
 *      samplinks: // value for 'samplinks'
 *      email: // value for 'email'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSamplesPdfMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(SamplesPdfDocument, options);
}
export const CreateTboCommentSampleDocument = gql`
    mutation createTboCommentSample($samplink: Int!, $comment: String!) {
  createTboCommentSample(samplink: $samplink, comment: $comment) {
    STC_SAMPLINK
    STC_COMMENT
  }
}
    `;

/**
 * __useCreateTboCommentSampleMutation__
 *
 * To run a mutation, you first call `useCreateTboCommentSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTboCommentSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTboCommentSampleMutation, { data, loading, error }] = useCreateTboCommentSampleMutation({
 *   variables: {
 *      samplink: // value for 'samplink'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateTboCommentSampleMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(CreateTboCommentSampleDocument, options);
}
export const SamplesPublishDocument = gql`
    mutation samplesPublish($samplinks: [Int!]!) {
  samplesPublish(samplinks: $samplinks)
}
    `;

/**
 * __useSamplesPublishMutation__
 *
 * To run a mutation, you first call `useSamplesPublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSamplesPublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [samplesPublishMutation, { data, loading, error }] = useSamplesPublishMutation({
 *   variables: {
 *      samplinks: // value for 'samplinks'
 *   },
 * });
 */
export function useSamplesPublishMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(SamplesPublishDocument, options);
}
export const UpdateSampleDocument = gql`
    mutation updateSample($samplink: Int!, $INTERM_NEW: String, $DTSAMPLING_NEW: Date, $TOTMILEAGE_NEW: Int, $SUMPMILEAGE_NEW: Int, $UNITMILEAGE_NEW: String, $OILMAKE_NEW: String, $OILTYPE_NEW: String, $OILGRADE_NEW: String, $OILCONSUMP_NEW: Float, $OILCONSUMP_UNIT_NEW: String, $COMM_CUST: String) {
  updateSample(
    samplink: $samplink
    INTERM_NEW: $INTERM_NEW
    DTSAMPLING_NEW: $DTSAMPLING_NEW
    TOTMILEAGE_NEW: $TOTMILEAGE_NEW
    SUMPMILEAGE_NEW: $SUMPMILEAGE_NEW
    UNITMILEAGE_NEW: $UNITMILEAGE_NEW
    OILMAKE_NEW: $OILMAKE_NEW
    OILTYPE_NEW: $OILTYPE_NEW
    OILGRADE_NEW: $OILGRADE_NEW
    OILCONSUMP_NEW: $OILCONSUMP_NEW
    OILCONSUMP_UNIT_NEW: $OILCONSUMP_UNIT_NEW
    COMM_CUST: $COMM_CUST
  ) {
    SAMPLINK
  }
}
    `;

/**
 * __useUpdateSampleMutation__
 *
 * To run a mutation, you first call `useUpdateSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSampleMutation, { data, loading, error }] = useUpdateSampleMutation({
 *   variables: {
 *      samplink: // value for 'samplink'
 *      INTERM_NEW: // value for 'INTERM_NEW'
 *      DTSAMPLING_NEW: // value for 'DTSAMPLING_NEW'
 *      TOTMILEAGE_NEW: // value for 'TOTMILEAGE_NEW'
 *      SUMPMILEAGE_NEW: // value for 'SUMPMILEAGE_NEW'
 *      UNITMILEAGE_NEW: // value for 'UNITMILEAGE_NEW'
 *      OILMAKE_NEW: // value for 'OILMAKE_NEW'
 *      OILTYPE_NEW: // value for 'OILTYPE_NEW'
 *      OILGRADE_NEW: // value for 'OILGRADE_NEW'
 *      OILCONSUMP_NEW: // value for 'OILCONSUMP_NEW'
 *      OILCONSUMP_UNIT_NEW: // value for 'OILCONSUMP_UNIT_NEW'
 *      COMM_CUST: // value for 'COMM_CUST'
 *   },
 * });
 */
export function useUpdateSampleMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(UpdateSampleDocument, options);
}
export const StatisticsDocument = gql`
    query statistics($custLink: Int!, $diagType: Int!, $yearFrom: Int!, $yearTo: Int!) {
  statistics(
    custLink: $custLink
    diagType: $diagType
    yearFrom: $yearFrom
    yearTo: $yearTo
  ) {
    year
    greyEngine
    greenEngine
    orangeEngine
    redEngine
    greyNonEngine
    greenNonEngine
    orangeNonEngine
    redNonEngine
  }
}
    `;
/**
 * __useStatisticsQuery__
 *
 * To run a query within a React component, call `useStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsQuery({
 *   variables: {
 *      custLink: // value for 'custLink'
 *      diagType: // value for 'diagType'
 *      yearFrom: // value for 'yearFrom'
 *      yearTo: // value for 'yearTo'
 *   },
 * });
 */

export function useStatisticsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(StatisticsDocument, options);
}
export function useStatisticsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(StatisticsDocument, options);
}
export function useStatisticsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(StatisticsDocument, options);
}
export const InvoicesCustomersDocument = gql`
    query invoicesCustomers {
  invoicesCustomers {
    id
    CST_CUSTLINK
    CST_CUSTCODE
    CST_GROUPCODE
    CST_NAME
    CST_CENTER
  }
}
    `;
/**
 * __useInvoicesCustomersQuery__
 *
 * To run a query within a React component, call `useInvoicesCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesCustomersQuery({
 *   variables: {
 *   },
 * });
 */

export function useInvoicesCustomersQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(InvoicesCustomersDocument, options);
}
export function useInvoicesCustomersLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(InvoicesCustomersDocument, options);
}
export function useInvoicesCustomersSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(InvoicesCustomersDocument, options);
}
export const StatisticsCustomersDocument = gql`
    query statisticsCustomers {
  statisticsCustomers {
    id
    CST_CUSTLINK
    CST_CUSTCODE
    CST_GROUPCODE
    CST_NAME
    CST_CENTER
  }
}
    `;
/**
 * __useStatisticsCustomersQuery__
 *
 * To run a query within a React component, call `useStatisticsCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsCustomersQuery({
 *   variables: {
 *   },
 * });
 */

export function useStatisticsCustomersQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(StatisticsCustomersDocument, options);
}
export function useStatisticsCustomersLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(StatisticsCustomersDocument, options);
}
export function useStatisticsCustomersSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(StatisticsCustomersDocument, options);
}
export const ManagementReportCustomersDocument = gql`
    query managementReportCustomers {
  managementReportCustomers {
    id
    CST_CUSTLINK
    CST_CUSTCODE
    CST_GROUPCODE
    CST_NAME
    CST_CENTER
  }
}
    `;
/**
 * __useManagementReportCustomersQuery__
 *
 * To run a query within a React component, call `useManagementReportCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagementReportCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagementReportCustomersQuery({
 *   variables: {
 *   },
 * });
 */

export function useManagementReportCustomersQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(ManagementReportCustomersDocument, options);
}
export function useManagementReportCustomersLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(ManagementReportCustomersDocument, options);
}
export function useManagementReportCustomersSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(ManagementReportCustomersDocument, options);
}
export const InvoicesXlsxExtractionDocument = gql`
    mutation invoicesXlsxExtraction($custLink: Int!, $from: Date!, $to: Date!) {
  invoicesXlsxExtraction(custLink: $custLink, from: $from, to: $to)
}
    `;

/**
 * __useInvoicesXlsxExtractionMutation__
 *
 * To run a mutation, you first call `useInvoicesXlsxExtractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoicesXlsxExtractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoicesXlsxExtractionMutation, { data, loading, error }] = useInvoicesXlsxExtractionMutation({
 *   variables: {
 *      custLink: // value for 'custLink'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useInvoicesXlsxExtractionMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(InvoicesXlsxExtractionDocument, options);
}
export const InvoicesPdfExtractionDocument = gql`
    mutation invoicesPdfExtraction($custLink: Int!, $from: Date!, $to: Date!) {
  invoicesPdfExtraction(custLink: $custLink, from: $from, to: $to)
}
    `;

/**
 * __useInvoicesPdfExtractionMutation__
 *
 * To run a mutation, you first call `useInvoicesPdfExtractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoicesPdfExtractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoicesPdfExtractionMutation, { data, loading, error }] = useInvoicesPdfExtractionMutation({
 *   variables: {
 *      custLink: // value for 'custLink'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useInvoicesPdfExtractionMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(InvoicesPdfExtractionDocument, options);
}
export const GenerateManagementReportDocument = gql`
    mutation generateManagementReport($custcode: String!, $from: String!, $to: String!) {
  generateManagementReport(custcode: $custcode, from: $from, to: $to)
}
    `;

/**
 * __useGenerateManagementReportMutation__
 *
 * To run a mutation, you first call `useGenerateManagementReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateManagementReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateManagementReportMutation, { data, loading, error }] = useGenerateManagementReportMutation({
 *   variables: {
 *      custcode: // value for 'custcode'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGenerateManagementReportMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(GenerateManagementReportDocument, options);
}
export const PartDefCodesTranslationsDocument = gql`
    query partDefCodesTranslations($locale: UserLocale, $isSelectDisplay: Boolean) {
  partDefCodesTranslations(locale: $locale, isSelectDisplay: $isSelectDisplay)
}
    `;
/**
 * __usePartDefCodesTranslationsQuery__
 *
 * To run a query within a React component, call `usePartDefCodesTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartDefCodesTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartDefCodesTranslationsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      isSelectDisplay: // value for 'isSelectDisplay'
 *   },
 * });
 */

export function usePartDefCodesTranslationsQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(PartDefCodesTranslationsDocument, options);
}
export function usePartDefCodesTranslationsLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(PartDefCodesTranslationsDocument, options);
}
export function usePartDefCodesTranslationsSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(PartDefCodesTranslationsDocument, options);
}
export const UsersDocument = gql`
    query users {
  users {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */

export function useUsersQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(UsersDocument, options);
}
export function useUsersLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(UsersDocument, options);
}
export function useUsersSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(UsersDocument, options);
}
export const MeDocument = gql`
    query me {
  me {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */

export function useMeQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(MeDocument, options);
}
export function useMeLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(MeDocument, options);
}
export function useMeSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(MeDocument, options);
}
export const GetMaintenanceDocument = gql`
    query getMaintenance {
  getMaintenance
}
    `;
/**
 * __useGetMaintenanceQuery__
 *
 * To run a query within a React component, call `useGetMaintenanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaintenanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaintenanceQuery({
 *   variables: {
 *   },
 * });
 */

export function useGetMaintenanceQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(GetMaintenanceDocument, options);
}
export function useGetMaintenanceLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(GetMaintenanceDocument, options);
}
export function useGetMaintenanceSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(GetMaintenanceDocument, options);
}
export const IsOnlyHitachiScopesDocument = gql`
    query isOnlyHitachiScopes {
  isOnlyHitachiScopes
}
    `;
/**
 * __useIsOnlyHitachiScopesQuery__
 *
 * To run a query within a React component, call `useIsOnlyHitachiScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsOnlyHitachiScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsOnlyHitachiScopesQuery({
 *   variables: {
 *   },
 * });
 */

export function useIsOnlyHitachiScopesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(IsOnlyHitachiScopesDocument, options);
}
export function useIsOnlyHitachiScopesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(IsOnlyHitachiScopesDocument, options);
}
export function useIsOnlyHitachiScopesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(IsOnlyHitachiScopesDocument, options);
}
export const AdminAssignableScopesDocument = gql`
    query adminAssignableScopes {
  adminAssignableScopes {
    contactCenter {
      label
      code
    }
    technicalBackOffice {
      label
      code
    }
    technicalDelegate {
      label
      code
    }
    dealer {
      label
      code
    }
    clientCorrespondant {
      label
      code
    }
    clientWithMachine {
      label
      code
    }
    commercialDelegate {
      label
      code
    }
    clientGroups {
      label
      code
    }
  }
}
    `;
/**
 * __useAdminAssignableScopesQuery__
 *
 * To run a query within a React component, call `useAdminAssignableScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAssignableScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAssignableScopesQuery({
 *   variables: {
 *   },
 * });
 */

export function useAdminAssignableScopesQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(AdminAssignableScopesDocument, options);
}
export function useAdminAssignableScopesLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(AdminAssignableScopesDocument, options);
}
export function useAdminAssignableScopesSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(AdminAssignableScopesDocument, options);
}
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(DeleteUserDocument, options);
}
export const CreateUserDocument = gql`
    mutation CreateUser($firstName: String!, $lastName: String!, $email: String!, $documentLocale: DocumentLocale!, $activate: Boolean!, $kitRequestEnabled: Boolean!, $role: Role!, $website: WebSite!, $scopes: [ScopeInput!]!, $profile: Profile!) {
  createUser(
    firstName: $firstName
    lastName: $lastName
    email: $email
    documentLocale: $documentLocale
    activate: $activate
    kitRequestEnabled: $kitRequestEnabled
    role: $role
    website: $website
    scopes: $scopes
    profile: $profile
  ) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      documentLocale: // value for 'documentLocale'
 *      activate: // value for 'activate'
 *      kitRequestEnabled: // value for 'kitRequestEnabled'
 *      role: // value for 'role'
 *      website: // value for 'website'
 *      scopes: // value for 'scopes'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(CreateUserDocument, options);
}
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $firstName: String!, $lastName: String!, $email: String!, $documentLocale: DocumentLocale!, $activate: Boolean!, $kitRequestEnabled: Boolean!, $role: Role!, $website: WebSite!, $scopes: [ScopeInput!]!, $profile: Profile!) {
  updateUser(
    id: $id
    firstName: $firstName
    lastName: $lastName
    email: $email
    documentLocale: $documentLocale
    activate: $activate
    kitRequestEnabled: $kitRequestEnabled
    role: $role
    website: $website
    scopes: $scopes
    profile: $profile
  ) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      documentLocale: // value for 'documentLocale'
 *      activate: // value for 'activate'
 *      kitRequestEnabled: // value for 'kitRequestEnabled'
 *      role: // value for 'role'
 *      website: // value for 'website'
 *      scopes: // value for 'scopes'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(UpdateUserDocument, options);
}
export const UpdateLocaleDocument = gql`
    mutation UpdateLocale($locale: UserLocale!) {
  updateLocale(locale: $locale) {
    id
    locale
  }
}
    `;

/**
 * __useUpdateLocaleMutation__
 *
 * To run a mutation, you first call `useUpdateLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocaleMutation, { data, loading, error }] = useUpdateLocaleMutation({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useUpdateLocaleMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(UpdateLocaleDocument, options);
}
export const UpdateDocumentLocaleDocument = gql`
    mutation UpdateDocumentLocale($documentLocale: DocumentLocale!) {
  updateDocumentLocale(documentLocale: $documentLocale) {
    id
    documentLocale
  }
}
    `;

/**
 * __useUpdateDocumentLocaleMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentLocaleMutation, { data, loading, error }] = useUpdateDocumentLocaleMutation({
 *   variables: {
 *      documentLocale: // value for 'documentLocale'
 *   },
 * });
 */
export function useUpdateDocumentLocaleMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(UpdateDocumentLocaleDocument, options);
}
export const UpdateProfileDocument = gql`
    mutation updateProfile($firstName: String!, $lastName: String!) {
  updateProfile(firstName: $firstName, lastName: $lastName) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(UpdateProfileDocument, options);
}
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!, $origin: String!, $locale: UserLocale!, $website: WebSite!) {
  forgotPassword(
    email: $email
    origin: $origin
    locale: $locale
    website: $website
  )
}
    `;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      origin: // value for 'origin'
 *      locale: // value for 'locale'
 *      website: // value for 'website'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(ForgotPasswordDocument, options);
}
export const ResetMyPasswordDocument = gql`
    mutation resetMyPassword($password: String!, $currentPassword: String!) {
  resetMyPassword(password: $password, currentPassword: $currentPassword)
}
    `;

/**
 * __useResetMyPasswordMutation__
 *
 * To run a mutation, you first call `useResetMyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetMyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetMyPasswordMutation, { data, loading, error }] = useResetMyPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useResetMyPasswordMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(ResetMyPasswordDocument, options);
}
export const IsResetPasswordTokenValidDocument = gql`
    query isResetPasswordTokenValid($id: ID!) {
  isResetPasswordTokenValid(id: $id)
}
    `;
/**
 * __useIsResetPasswordTokenValidQuery__
 *
 * To run a query within a React component, call `useIsResetPasswordTokenValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsResetPasswordTokenValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsResetPasswordTokenValidQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */

export function useIsResetPasswordTokenValidQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(IsResetPasswordTokenValidDocument, options);
}
export function useIsResetPasswordTokenValidLazyQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(IsResetPasswordTokenValidDocument, options);
}
export function useIsResetPasswordTokenValidSuspenseQuery(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useSuspenseQuery(IsResetPasswordTokenValidDocument, options);
}
export const ResetPasswordDocument = gql`
    mutation resetPassword($resetPasswordTokenId: ID!, $password: String!) {
  resetPassword(resetPasswordTokenId: $resetPasswordTokenId, password: $password)
}
    `;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordTokenId: // value for 'resetPasswordTokenId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(ResetPasswordDocument, options);
}
export const SetKpisDocument = gql`
    mutation setKpis($kpis: [KpiInput!]) {
  setKpis(kpis: $kpis) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useSetKpisMutation__
 *
 * To run a mutation, you first call `useSetKpisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetKpisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setKpisMutation, { data, loading, error }] = useSetKpisMutation({
 *   variables: {
 *      kpis: // value for 'kpis'
 *   },
 * });
 */
export function useSetKpisMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(SetKpisDocument, options);
}
export const SetMaintenanceDocument = gql`
    mutation setMaintenance($status: Boolean!) {
  setMaintenance(status: $status)
}
    `;

/**
 * __useSetMaintenanceMutation__
 *
 * To run a mutation, you first call `useSetMaintenanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMaintenanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMaintenanceMutation, { data, loading, error }] = useSetMaintenanceMutation({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetMaintenanceMutation(baseOptions) {
  const options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(SetMaintenanceDocument, options);
}